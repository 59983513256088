@tailwind base;
@tailwind components;
@tailwind utilities;

@import '~bootstrap-icons/font/bootstrap-icons';
@import '~bootstrap-icons/font/bootstrap-icons';
@import '~owl.carousel/dist/assets/owl.carousel.css';
@import '~owl.carousel/dist/assets/owl.theme.default.css';

@layer base {
    a {
            @apply transition hover:text-gray-700/75;
    }

    h1 {
        @apply text-3xl;
    }

    h2 {
        @apply text-xl;
    }

    .h3-label{
        @apply text-2xl font-extrabold mb-3 border-b pb-2 text-red-600 font-sans mb-3 pb-0 border-b border-red-600;
    }


}

@layer  components {
    .btn-more {
            @apply bg-zinc-200 hover:bg-zinc-100 text-sm text-zinc-900 font-medium py-1 px-4 rounded;
    }

    .btn-more-red {
        @apply bg-red-600 hover:bg-zinc-100 text-sm text-white font-medium py-1 px-4 rounded;
    }

    .card {
        @apply md:shadow-lg md:border bg-white rounded-t-none md:rounded-t-lg rounded-lg p-6 mx-[-1rem] md:mx-0;
    }

    .carousel-item {
        @apply shadow-lg border bg-white rounded-sm p-2 ;
    }

    .tag{
        @apply text-xs font-semibold inline-block py-1 px-2 text-red-600 bg-red-200 last:mr-0 mr-1;
    }

    .accordion-content {
        @apply hidden;
    }


}

@layer utilities{
    .cut-text {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }

    .cut-text-1 {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .cut-text-2 {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .cut-text-3 {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }




/*
        .article-content-full > h2{
            font-size: 1.5em;
            margin-top: 0.83em;
            margin-bottom: 0.83em;
            font-weight: bold;
        }
        .article-content-full > h3 {
            font-size: 1.17em;
            margin-top: 1em;
            margin-bottom: 1em;
            font-weight: bold;
        }
        .article-content-full > h4{
            margin-top: 1.33em;
            margin-bottom: 1.33em;
            font-weight: bold;
        }
        .article-content-full > h5 {
            font-size: .83em;
            margin-top: 1.67em;
            margin-bottom: 1.67em;
            font-weight: bold;
        }
        .article-content-full > h6{
            font-size: .67em;
            margin-top: 2.33em;
            margin-bottom: 2.33em;
            font-weight: bold;
        } */
}

.rtl {
    direction: rtl;
    text-align: right;
    unicode-bidi: bidi-override;
}

.ar-16-9 {
    aspect-ratio: 16 / 9;
}

.ar-1-1 {
    aspect-ratio: 1 / 1;
}


.cat-title {
    margin-bottom: 1rem !important;
}

.owl-stage {
    padding-left: 0 !important;
}

.figure > img{
    @apply w-full rounded block;
}

.figure > span {
    @apply block text-xs text-gray-600 text-center;
}

.figure-caption {
    @apply block text-xs text-gray-600 text-center;
}


/* ofm navbar */

/* .ofm-navbar {
    display: flex;
    width: 100%;
    box-sizing: border-box;
}

.ofm-context-bar {
    flex: 1;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.ofm-context-bar {
    height: 30px;
}

.ofm-link {
    display: inline-block;
}

.ofm-dropdown-link {
    display: inline-block;
    margin: 0;
    padding: 10px 10px;
    text-align: center;
}

.ofm-link.active,
.ofm-dropdown-link.active {}

.ofm-link:hover {
    background-color: #f9f9f9;
}

.ofm-dropdown:hover {
    background-color: #999;
}

.ofm-dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    right: 0;
    z-index: 100000;
}

.ofm-dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
}

.ofm-dropdown-content a:hover {
    background-color: #f1f1f1
} */
